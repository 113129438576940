
import { defineComponent, ref } from 'vue'
import useLogin from '@/composables/useLogin'
import router from '@/router'

export default defineComponent({
  name: 'Authentification',
  setup () {
    const email = ref<string>('')
    const password = ref<string>('')

    const { error, login } = useLogin()

    const handleSubmit = async () => {
      await login(email.value, password.value)
      if (!error.value) {
        router.push({name: 'Home', params:{id: 'ymDAKSzkNSZYedYpREvP'}})
      }
    }

    return { email, password, handleSubmit, error }
  }
})
