
import {defineComponent} from "vue";

export default defineComponent ({
  name: "Audio",
  props: {
    audio: {
      type: String,
      required: true
    }
  },
  setup(props) {
    console.log("Audio", props.audio)
    return {}
  }
})
