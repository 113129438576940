import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import Room from '@/types/Room'

const getRoom = (collection: string, conditionField: string, id: number) => {

    const documents = ref<Room[] | null>(null)
    const error = ref<string | null>(null)
    const load = async () => {
        try {
            const res = await projectFirestore.collection(collection).where(conditionField, "==", id).limit(1).get()
            documents.value = res.docs.map(doc => {
                return { ...doc.data() as Room, id: doc.id }
            })
            error.value = null
        } catch (err) {
            console.log("Error: ", err.message)
            documents.value = null
            error.value = 'could not fetch the data'
        }
    }

    return { error, documents, load }
}

export default getRoom