
import {defineComponent, ref} from "vue";
import Modal from "@/components/Modal.vue";
import Audio from "@/components/Audio.vue"
import { VueCookieNext } from 'vue-cookie-next'


export default defineComponent ( {
  name: "App",
  components: {
    Modal,
    Audio
  },
  setup() {
    const gameExists = ref(false)
    const modalContent = ref<string>()
    const modalLink = ref<string>()
    const modalPlayerName = ref<string>()
    const modalSolutionChat = ref<string>()
    const modalSolutionCode = ref<string>()
    const modalTimestamp = ref<string>()
    const audio = ref<string>('')

    console.log("App")

    VueCookieNext.config({ expire: '30d' })

    const updateModal = (compName: string, link: string, playerName: string, solutionChat: string, solutionCode: string, timestamp: string ) => {
      console.log('solutionChat', solutionChat)
      console.log('solutionCode', solutionCode)
      console.log('timestamp', timestamp)
      switch (compName) {
        case "phone":
          modalContent.value = 'Phone'
          modalPlayerName.value = playerName
          modalSolutionChat.value = solutionChat
          modalSolutionCode.value = solutionCode
          modalTimestamp.value = timestamp
          break
        case "image":
          modalContent.value = "Image"
          modalLink.value = link
          break;
        case "audio":
          audio.value = link
          break;
        case "component":
          modalContent.value = "Iphone"
          break;
        default:
          modalContent.value = compName
      }
    }

    return { gameExists, updateModal, modalContent, modalLink, modalPlayerName, modalSolutionChat, modalSolutionCode, modalTimestamp, audio }
  }
})



