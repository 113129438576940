export default {
    cookie: 'escapeIfYouCan',
    project: 'birthday-party',
    player: [
        {
            name: 'lisa',
            img: 'https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/phone%2Fwhatsapp%2Flisa_profile.png?alt=media&token=6ba6e4e6-70b0-4cf6-bfb6-dbabc5f12547'
        },
        {
            name: 'paul',
            img: 'https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/phone%2Fwhatsapp%2Fpaul_profile.png?alt=media&token=4b4aeca4-82af-4f7d-9e6e-56d060bbc6bb'
        }
    ]
}
