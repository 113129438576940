
import config from "@/config"
import {defineComponent, ref, watch, reactive, computed} from "vue";
import getPanos from "@/composables/getPanos";
import Room from "@/types/Room";
import Hotspot from "@/types/Hotspot";
import {useRoute} from "vue-router";
import router from "@/router";
import {projectFirestore} from "@/firebase/config";
import useLogout from "@/composables/useLogout"

export default defineComponent({
  name: "AdminPano",
  data() {
    return {
      player1: "0",
      player2: "1",
    }
  },
  props: {
    view: {
      type: Object,
      required: true
    },
    viewer: {
      type: Object,
      required: true
    },
    hotspots: {
      type: Map,
      required: true
    },
    player: {
      type: String,
      default: "1"
    }
  },

  setup(props, context) {
    const route = useRoute()
    const projectName = config.project
    const playerNames = config.player.map(item => item.name)
    const adminState = ref(true)
    const rooms = ref<Room[] | null>(null)
    const hotspotId = ref<number | string>("")
    const hotspot = reactive<Hotspot>({
      name: '',
      yaw: 0 as number | string,
      pitch: 0 as number | string,
      type: 'image',
      link: '',
      width: 100,
      height: 100
    })

    const view = computed(() => {
      console.log("View Computed", props.view)
      return props.view
    })

    const viewer = computed(() => {
      console.log("Viewer Computed", props.viewer)
      return props.viewer
    })

    const roomId = computed(() => {
      console.log("Rout", route.params.room)
      return route.params.room
    })

    const playerId = computed(() => {
      return props.player
    })

    const chgPlayer = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target) {
        context.emit('update:modelPlayer', target.value)
      }
    }

    const hotspotList = computed(() => {
      if (!props.hotspots) return []
      return props.hotspots.get(+playerId.value)
    })


    const {error, documents, load} = getPanos(`projects/${projectName}/rooms/`)
    load()

    watch(documents, () => {
      if (!error.value) {
        rooms.value = documents.value
      }
    })

    var pano = document.getElementById("pano");
    if (pano) {
      pano.addEventListener("click", function (e) {
        hotspot.yaw = view.value.screenToCoordinates({x: e.clientX, y: e.clientY}).yaw
        hotspot.pitch = view.value.screenToCoordinates({x: e.clientX, y: e.clientY}).pitch
      })
    }

    const changePano = (event: Event) => {
      const target = event.target as HTMLSelectElement
      if (target) {
        router.push({name: 'Room', params: {room: target.value, playerId: playerId.value, playerName: playerNames[+playerId.value]}})
      }
    }
/*
    const hotspotList = computed(() => {
      if (viewer.value) {
        const scene = viewer.value.scene();
        console.log("list", scene.hotspotContainer().listHotspots())
        return scene.hotspotContainer().listHotspots()
      } else {
        return false
      }
    })
*/

    const selectHotspot = (event: Event) => {
      const target = event.target as HTMLSelectElement
      if (target && hotspotId.value !== "") {
        console.log("HotspotId", hotspotId.value)
        const selectedHotspot =  JSON.parse(JSON.stringify(hotspotList.value))[target.value]
        hotspot.name = selectedHotspot.name
        hotspot.yaw = selectedHotspot.yaw
        hotspot.pitch = selectedHotspot.pitch
        hotspot.type = selectedHotspot.type
        hotspot.link = selectedHotspot.link
        hotspot.width = selectedHotspot.width
        hotspot.height = selectedHotspot.height
      }
    }

    const placeHotspot = () => {
      console.log("Place")
      const newHotspot = document.createElement('div');
      newHotspot.classList.add('hotspot');
      newHotspot.classList.add('hotspot__new');
      newHotspot.style.width = hotspot.width.toString() + "px"
      newHotspot.style.height = hotspot.height.toString() + "px"
      newHotspot.textContent = hotspot.name
      const scene = viewer.value.scene();
      const hs = scene.hotspotContainer().createHotspot(newHotspot, {yaw: hotspot.yaw, pitch: hotspot.pitch})
      newHotspot.addEventListener('click', function () {
        scene.hotspotContainer().destroyHotspot(hs)
      });
    }

    const saveHotspot = async () => {
      const docRef = projectFirestore.collection(`projects/${projectName}/rooms`).doc(`${roomId.value}`)
      docRef.get().then((doc) => {
        if (doc.exists) {
          const panos = doc.data()?.panos
          if (hotspotId.value !== ""){
            panos[+playerId.value].hotspots[+hotspotId.value] = JSON.parse(JSON.stringify(hotspot))
          } else {
            panos[+playerId.value].hotspots.push(JSON.parse(JSON.stringify(hotspot)))
          }

          docRef.update({
            "panos": panos
          })
          .then(() => {
            console.log("Document successfully updated!");
            context.emit('loadPanoData', null)
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }

    const deleteHotspot = async () => {
      const docRef = projectFirestore.collection(`projects/${projectName}/rooms`).doc(`${roomId.value}`)
      docRef.get().then((doc) => {
        if (doc.exists) {
          const panos = doc.data()?.panos
          panos[+playerId.value].hotspots.splice(hotspotId.value, 1)

          docRef.update({
            "panos": panos
          })
              .then(() => {
                console.log("Document successfully updated!");
                context.emit('loadPanoData', null)
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }

    watch(adminState, () => {
      document.body.classList.toggle('admin')
    })

    const { error: logoutError, logout } = useLogout()

    const handleLogout = async () => {
      await logout()
      if (!logoutError.value) {
        console.log("User logged out")
      }
    }

    return {playerId, rooms, hotspot, hotspotId, hotspotList, roomId, changePano, placeHotspot, saveHotspot, selectHotspot, deleteHotspot, chgPlayer, adminState, handleLogout}
  }
})
