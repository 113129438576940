
import {computed, defineAsyncComponent, defineComponent, onMounted, ref} from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    componentName: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    playerName: {
      type: String,
      required: false
    },
    timestamp: {
      type: String,
      required: true
    }
  },
  setup(props, context) {

    const componentName = ref(props.componentName)

    const component = computed(() => {
      return defineAsyncComponent(() => import("@/components/modals/" + componentName.value + ".vue"))
    })

    const handleClick = () => {
      context.emit('closeModal', null)
    }

    onMounted(() => {
      console.log("Modal mounted", componentName.value)
    })

    return {component, handleClick}
  }
})
