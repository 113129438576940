
import {defineComponent, reactive, ref, watch} from "vue";
import getState from "@/composables/getState";
import {useRoute} from "vue-router";
import config from "@/config";

export default defineComponent({
  name: "Navigation",
  props: {
    playerId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const route = useRoute()
    const id = route.params.id
    const roomId = route.params.room
    const playersRef = reactive(config.player as { [k: string]: any })
    const navRef = ref<HTMLElement | null>(null)
    const toggleNav = () => {
      navRef.value?.classList.toggle('open')
    }

    // add current online/offline state to playersRef
    const {error: errorState, documents: stateDoc, load} = getState(`/games/${id}/players/`)
    load()
    watch(stateDoc, () => {
      if (stateDoc.value && !errorState.value) {
        for (let key in stateDoc.value) {
          playersRef[key].state = stateDoc.value[key].status.state
          playersRef[key].room = stateDoc.value[key].room
        }
      }
    })

    return {toggleNav, navRef, stateDoc, playersRef, roomId}
  }
})
