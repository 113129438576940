<template>
  <teleport to="body">
    <div id="pano" ref="pano"></div>
  </teleport>
  <Navigation :playerId="playerIdRef"></Navigation>
  <AdminPano v-if="(user || local)" :view="viewRef" :viewer="viewerRef" :hotspots="hotspotRef" :player="playerIdRef"
             @update:model-player="playerIdRef = $event" @loadPanoData="loadPano"></AdminPano>
</template>

<script lang="ts">

import router from "@/router";

declare global {
  interface Window {
    Marzipano: any;
  }
}

import {computed, defineComponent, ref, watch, onMounted} from 'vue';
import {useRoute} from "vue-router";
import Config from '@/config'
import Pano from '@/types/Pano'
import Hotspot from '@/types/Hotspot'
import AdminPano from '@/components/AdminPano.vue'
import getUser from '@/composables/getUser'
import getRoom from "@/composables/getRoom.ts";
import {useCookie} from "vue-cookie-next";
import updateState from "@/composables/updateState";
import firebase from "firebase";
import Navigation from "@/components/Navigation.vue";

export default defineComponent({
  name: 'Room',
  inheritAttrs: false,
  components: {
    Navigation,
    AdminPano
  },
  props: {
    playerId: {
      type: String,
      default: ''
    },
    playerName: {
      type: String,
      default: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    const progress = to.meta.projectProgress as Record<number, string>
    const roomId = to.params.room[0] || to.params.room
    // check if room has been finished
    if (progress[+roomId]) {
      // if true: try next room
      const nextRoom = +roomId + 1
      next({replace: true, name: 'Room', params: {id: to.params.id, room: nextRoom}})
    } else {
      // if false: check if last room has been finished
      if (roomId !== '1' && !progress[+roomId - 1]) {
        // if not: go Home
        next({replace: true, name: 'Home', params: {id: to.params.id}})
      } else {
          next()
      }
    }
  },
  setup: function (props, context) {
    const route = useRoute()
    const cookie = useCookie()
    const config = Config
    const cookieExists = cookie.isCookieAvailable(Config.cookie)
    const playerNames = config.player.map(item => item.name)
    const id = route.params.id
    const room = route.params.room
    const projectName = config.project
    const viewRef = ref(null)
    const viewerRef = ref(null)
    let viewer: any = null
    let scenes: any = []
    const hotspotArr: Hotspot[] = []
    const hotspots = new Map<number, Hotspot[]>()
    const hotspotRef = ref<Map<number, Hotspot[]> | null>(null)
    const playerIdRef = ref<number>(parseInt(props.playerId))
    const playerNameRef = ref<string>(props.playerName)
    const local = ref<boolean>(window.document.location.hostname === '192.168.178.32')

    if (!playerIdRef.value) {
      if (cookieExists) {
        playerIdRef.value = cookie.getCookie(config.cookie).player_id
        playerNameRef.value = cookie.getCookie(config.cookie).player_name
      } else {
        router.push({name: 'Home'})
      }
    }

    // set status online and current room
    const { error: errorState, updateDoc } = updateState()
    const ts = new firebase.firestore.Timestamp(Math.floor(Date.now() / 1000), 0)
    const status = {
      status: {
        last_changed: ts,
        state: 'online'
      },
      room: +room
    }
    updateDoc(`games/${id}/players`, playerIdRef.value, status)
    if (errorState.value) {
      console.log(errorState.value)
    }

    // check if user is logged in for administration
    const {user} = getUser()

    console.log("Room", room)
    console.log("ProjectName", projectName)

    const {error: errorRoom, documents: roomDocsRef, load: loadPano} = getRoom(`projects/${projectName}/rooms/`, "room", +room)
    loadPano()


    // if pano data needs to be processed
    /*
    const formattedDocuments = computed(() => {
      if (documents.value) {
        return documents.value.map((doc: Room) => {
          return {...doc}
        })
      } else {
        console.log("Error: ", error.value)
        return false
      }
    })
    */


    // Viewer options.
    const viewerOpts = {
      "settings": {
        "mouseViewMode": "drag",
        "autorotateEnabled": true,
        "fullscreenButton": true,
        "viewControlButtons": true
      }
    }

    const Marzipano = computed(() => {
      return window.Marzipano || {}
    })

    onMounted(() => {
      const panoElement = document.getElementById('pano');

      // Initialize viewer
      viewer = new Marzipano.value.Viewer(panoElement, viewerOpts);
      viewerRef.value = viewer
    })


    watch(roomDocsRef, () => {
      console.log("Error:", errorRoom.value)
      if (!errorRoom.value) {
        console.log("roomDocs.value", Object.assign({}, roomDocsRef))
        console.log("roomDocs", roomDocsRef.value)
        if (!roomDocsRef.value) {
          console.log("Error: Pano could not be loaded.")
          return
        }

        const roomData = JSON.parse(JSON.stringify(roomDocsRef.value))[0] // ['player' + props.player]
        console.log("roomData", roomData)

        // Create scenes.
        scenes = roomData.panos.map((data: Pano, index: number) => {

          // Create source.
          const source = Marzipano.value.ImageUrlSource.fromString(data.url)

          // Create geometry.
          const geometry = new Marzipano.value.EquirectGeometry([{width: 5120}])

          const initialView = {
            yaw: data.yaw * Math.PI / 180,
            pitch: 0 * Math.PI / 180,
            fov: 120 * Math.PI / 180
          };

          // Create view.
          const limiter = Marzipano.value.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180);
          const view = new Marzipano.value.RectilinearView(initialView, limiter);

          // Create scene.
          const scene = viewer.createScene({
            source: source,
            geometry: geometry,
            view: view,
            pinFirstLevel: true
          })

          // const {error, documents: hotspotsDoc, load: loadHotspots} = getHotspots(`projects/birthdayParty/rooms/${roomData.id}/hotspots`, "pano",  index + 1)
          // loadHotspots()

          if (data.hotspots) {
            hotspotArr.length = 0
            data.hotspots.forEach((item: Hotspot) => {
              const hsDiv = document.createElement('div')
              hsDiv.classList.add('hotspot')
              hsDiv.style.width = item.width + "px"
              hsDiv.style.height = item.height + "px"
              hsDiv.textContent = item.name
              hsDiv.setAttribute('data-name', item.name)
              scene.hotspotContainer().createHotspot(hsDiv, {
                yaw: item.yaw,
                pitch: item.pitch,
              });
              hsDiv.addEventListener('click', function () {
                console.log("RoomData", roomData)
                context.emit('triggerModal', item.type, item.link, playerNameRef.value, roomData.solution.chat, roomData.solution.code, roomData.timestamp.seconds.toString())
              })
              hotspotArr.push(item)
            })
            hotspots.set(index, [...hotspotArr])
          }


          return {
            scene: scene,
            view: view
          }
        })

        hotspotRef.value = hotspots
        console.log("End watch roomDocs", hotspots)

        viewer.stage().addEventListener('renderComplete', function (eventName: string, complete: boolean) {
          console.log("Render Complete", eventName, complete)
        });


        /*
              watch(hotspotsDoc, () => {
                hotspots.value = hotspotsDoc.value
                console.log("Hotspots", hotspots.value )
              })
        */

        // Display scene.
        console.log("SwitchTo", scenes[playerIdRef.value].scene)
        scenes[playerIdRef.value].scene.switchTo()
        viewRef.value = scenes[playerIdRef.value].view
      }
    })

      /*onUpdated(() => {
        console.log("Updated")
        if (hotspots.value) {
          JSON.parse(JSON.stringify(hotspots.value)).forEach((item: any) => {
            const elem: HTMLElement | null = document.getElementById(item.name)
            if (elem) {
              scene?.hotspotContainer().createHotspot(elem, {yaw: item.yaw, pitch: item.pitch});
              elem.addEventListener('click', function () {
                console.log("Hinweis")
                context.emit('triggerModal', 'test')
              })
            }
          })
        }
      })*/

      watch(playerIdRef, () => {
        scenes[playerIdRef.value].scene.switchTo()
        viewRef.value = scenes[playerIdRef.value].view
        playerNameRef.value = playerNames[playerIdRef.value]
      })


      return {hotspotRef, viewRef, viewerRef, playerIdRef, loadPano, user, local}
    }
})
</script>

<style>

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
