

import {defineComponent} from 'vue';
import router from "@/router";
import config from "@/config";
import {useRoute} from "vue-router";
import { useCookie } from "vue-cookie-next";
import firebase from "firebase";


export default defineComponent({
  setup() {
    const route = useRoute()
    const cookie = useCookie()
    const id = route.params.id
    const playerNames = config.player.map(item => item.name)

    const enterRoom = (playerId: number, playerName: string) => {
      const escapeIfYouCan = `{"player_id":"${playerId}","player_name":"${playerName}","game_name":"${config.project}","game_id":"${id}","game_start_time":"${new Date()}"}`
      if (!cookie.isCookieAvailable(config.cookie) || cookie.getCookie(config.cookie).game_name !== config.project) {
        cookie.setCookie(config.cookie, escapeIfYouCan)
      }

      // Create a reference to this user's specific status node.
      // This is where we will store data about being online/offline.
      var userStatusDatabaseRef = firebase.database().ref(`/status/${id}/${playerId}`);

      // We'll create two constants which we will write to
      // the Realtime database when this device is offline
      // or online.
      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };

      // Create a reference to the special '.info/connected' path in
      // Realtime Database. This path returns `true` when connected
      // and `false` when disconnected.
      firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
          return;
        }

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
      });

      router.push({name: 'Room', params: {room: '1', playerId: playerId, playerName: playerName}})

    }
    return {enterRoom, playerNames}
  }
})


