import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyC7ZNMUaHc8fBpxcqcQ5nHEHTCixB1BGf0",
    authDomain: "escape-if-you-can-02.firebaseapp.com",
    projectId: "escape-if-you-can-02",
    storageBucket: "escape-if-you-can-02.appspot.com",
    messagingSenderId: "977238315800",
    appId: "1:977238315800:web:ec93a68eed9c24b86bc46d",
    databaseURL: "https://escape-if-you-can-02-default-rtdb.firebaseio.com/"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init authentification
const projectAuth = firebase.auth()
if (location.hostname === "localhost" || location.hostname === "192.168.178.31") {
    projectAuth.useEmulator("http://localhost:9099");
}

// init firestore service
const projectFirestore = firebase.firestore()
if (location.hostname === "localhost" || location.hostname === "192.168.178.31") {
    projectFirestore.useEmulator("localhost", 4000);
}

// init firebase functions
const projectFunctions = firebase.functions()
if (location.hostname === "localhost" || location.hostname === "192.168.178.31") {
    projectFunctions.useEmulator("localhost", 5001);
}

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp()


// init firebase database
const projectDatabase = firebase.database()
if (location.hostname === "localhost" || location.hostname === "192.168.178.31") {
    projectDatabase.useEmulator("localhost", 9000);
}


// export firestore
export { projectAuth, projectFirestore, projectFunctions, timestamp }
