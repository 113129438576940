import { projectFirestore} from "@/firebase/config";
import {ref} from "vue";
import PlayerState from "@/types/PlayerState";

const updateState = () => {

    const error = ref<string | null>(null)

    const updateDoc = async (collection:string, doc:number, obj:PlayerState) => {
        error.value = null

        try {
            await projectFirestore.collection(collection).doc(doc.toString()).set(obj)
        } catch (err) {
            console.log("Error", err.message)
            error.value = 'Could not update status: ' + err.message
        }
    }
    return { error, updateDoc }
}

export default updateState
