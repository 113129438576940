import { projectFirestore } from "@/firebase/config";
import {ref} from "vue";

const getGame = async (id: string) => {

    const error = ref<string | null>(null)
    const project = ref<any | null>(null)

    try {
        const res = await projectFirestore.collection('games').doc(id).get()
        if (res.exists) {
            error.value = null
            project.value =  { ...res.data(), id: res.id }
        } else {
            throw new Error('The game does not exists');
        }
    } catch (err) {
        console.log("Error: ", err.message)
        project.value = null
        error.value = 'The game does not exists'
    }

    return { error, project }
}

export default getGame
