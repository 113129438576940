import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import PlayerState from "@/types/PlayerState";


const getState = (collection: string) => {

    const documents = ref<PlayerState[] | null>(null)
    const error = ref<string | null>(null)

    const load = async () => {

        // register the firestore collection reference
        const collectionRef = await projectFirestore.collection(collection)

        const unsub = collectionRef.onSnapshot((snap: { docs: any[]; }) => {
            const results: PlayerState[] = []
            snap.docs.forEach(doc => {
                // must wait for the server to create the timestamp & send it back
                // doc.data().createdAt && results.push({...doc.data(), id: doc.id})
                results.push(<PlayerState>{...doc.data(), id: doc.id})
            });

            // update values
            documents.value = results
            error.value = null
        }, (err: { message: any; }) => {
            console.log(err.message)
            documents.value = null
            error.value = 'could not fetch the data'
        })

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });
    }

    return { error, documents, load }
}

export default getState
